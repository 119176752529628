import React, {useState} from 'react';
import './RecoverPassword.scss';
import { Container, Row, Col, Card, CardTitle, CardBody, FormGroup, Input, CardText, Button , FormFeedback} from 'reactstrap';
import logo from '../../../assets/img/logo-orange.png';
import HttpHandlerServices from '../../../services/httpService';
import { ToastContainer, toast } from 'react-toastify';

const InputPassword = (props) => {

    const hash = props.match.params.hash ? props.match.params.hash : null;

    const [password, setPassword] = useState({
        password: null,
        password1: null
    });

    const [validate, setValidate] = useState();

    const [none, setNone] = useState('d-none');


    const onchange = (e) => {

        setPassword({...password, [e.target.name]: e.target.value});
        
    }

    const sendData = (data) => {

        const endpoint = '/reset';

        const httpHandlerServices = new HttpHandlerServices();

        httpHandlerServices.postMethod(endpoint, data).then(
            async (res) => {
                const data = await res;

                if (data) {

                    toast.warning(`LA CONTRASEÑA FUE RESETEADA vuelta a ingresar`, {
    
                        position: toast.POSITION.BOTTOM_RIGHT,
    
                        autoClose: 8000
    
                    });
                }
            
                props.history.push(`/`);
            },
            async (error) => {
                const err = await error;
                if(err) {
                    
                    toast.error(``, {
    
                        position: toast.POSITION.BOTTOM_RIGHT,
    
                        autoClose: 8000
    
                    });
                
                }
            }

        );

    }
    
    const sendPassword = () => {

        if (password.password === password.password1 && password.password !== null){

            setValidate(false);

            const data = {
                token: hash,
                password: password.password,
            }

            sendData(data)

    
        } else {

            setValidate(true);

            setNone('d-none');

        }
    }


    return(

        <Container className="login">
            <ToastContainer />
            <Row>
                <Col>
                    <Card className="mt-5 p-3">
                        <CardTitle align="center">
                            <img src={logo} className="img-fluid mb-3 mt-5" alt="Little Caesars"/>
                            <h4 className="text-dark emigre-bold text-uppercase">restablecer de contraseña</h4>
                            <p className="text-secondary">Ingresa tu nueva contraseña.</p>
                        </CardTitle>
                        <CardBody>
                            <FormGroup>
                                <Input 
                                    type="password" 
                                    name="password" 
                                    id="password" 
                                    placeholder="NUEVA CONTRASEÑA" 
                                    className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold"
                                    onChange={(e)=>onchange(e)}
                                    invalid={validate}
                                />
                                <FormFeedback color="danger" className="my-2 text-center"><span className="text-white bg-danger rounded-circle mr-1">i</span>Verifica que las contraseña coincidan</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Input 
                                    type="password" 
                                    name="password1" 
                                    id="password1" 
                                    placeholder="CONFIRMAR NUEVA CONTRASEÑA" 
                                    className="rounded-0 border-top-0 border-right-0 border-left-0 emigre-bold"
                                    onChange={(e)=>onchange(e)}
                                    invalid={validate}
                                />
                                <FormFeedback color="danger" className="my-2 text-center"><span className="text-white bg-danger rounded-circle mr-1">i</span> Verifica que las contraseña coincidan </FormFeedback>
                            </FormGroup>
                        </CardBody>
                        <CardText align="center">
                            <Button color="primary" className="text-white rounded-0 emigre-bold mb-5 px-5 mt-2" size="lg"
                                onClick = {sendPassword}
                            >CAMBIAR CONTRASEÑA</Button>
                        </CardText>
                        <CardBody className={none}>
                            <p className="text-secondary text-center">Tu contraseña fue actualizda</p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default InputPassword;
