import React, { useContext, useState, useEffect } from "react"
import { Container, Row, Col, Card, Badge, CardBody, CardTitle } from 'reactstrap'
import { contextApp } from "../../../App/contextApp"
//import HistoricalChart from "./HistoricalChart/HistoricalChart";
import TimeAverage from './TimeAverage/TimeAverage';
import TimeClosingAvg from "./timeclosingAvg";

import language from './language.json';


const HistoricalTotal = (props) =>{
    
    const { currentLanguage, selectValue  } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

        
    },[currentLanguage])
    
    const { dataCategoriesResume, dataGeneral } = props;
    //const { avg_close, avg_time } = dataCategoriesResume;
    const { monthsLabel } = selectValue;
   
    const complaintAvg = () => {

        const avg = dataGeneral.complaint / dataGeneral.users;

        return avg.toFixed(2);

    }

    return(
            <Container tag="section" className="historical">
                <Row tag="article">
                    <Col xs={12}>
                        <h4 className="emigre-bold text-secondary text-uppercase">{texts.historyTotal}</h4>
                    </Col>
                    <Col xs={12}>
                        <Row className="mb-4">
                            <Col xs={12} md={4}>
                                <Card className="p-3">
                                    <CardTitle align="center">
                                        <p className="m-0 text-uppercase"><i className="icomoon icon-user_service mr-2 "></i>{texts.totalClients}</p>
                                    </CardTitle>
                                    <CardBody>
                                        <h3 className="text-center">{dataGeneral.users ? dataGeneral.users : 0} <Badge color="primary" className="line-izq text-white py-2 px-3 ml-4 badge-line">{dataGeneral.userPerc ? dataGeneral.userPerc : '0%'}</Badge></h3>
                                        <p className='text-center text-secondary'>
                                            {texts.infoText}
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card color='primary' className="text-white p-3">
                                    <CardTitle align="center">
                                        <p className="m-0 text-uppercase"><i className="icomoon icon-conversando mr-2"></i>{texts.totalIncidents}</p>
                                    </CardTitle>
                                    <CardBody>
                                        <h3 className="text-white text-center">{dataGeneral.complaint ? dataGeneral.complaint : 0} <Badge color="dark" className="line-izq text-white py-2 px-3 ml-4 badge-line">{dataGeneral.complaintPerc ? dataGeneral.complaintPerc : '0%'}</Badge></h3>
                                        <p className='text-center text-secondary'>
                                        {texts.infoText}
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xs={12} md={4}>
                                <Card className="p-3">
                                    <CardTitle align="center">
                                        <p className="m-0 text-uppercase">
                                            <i className="icomoon icon-user_service mr-2"></i> 
                                            {texts.averageComplaintsPerClient}</p>
                                    </CardTitle>
                                    <CardBody>
                                        <h3 className="text-center">{dataGeneral.users ? complaintAvg() : 0} <Badge color="primary" className="line-izq text-white py-2 px-3 ml-4 badge-line">{dataGeneral.userPerc ? dataGeneral.userPerc : '0%'}</Badge></h3>
                                        <p className='text-center text-secondary'>
                                        {texts.infoText}
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col> 
                        </Row>
                        <TimeAverage 
                            avg_close={ dataCategoriesResume.avg_close } 
                            avg_time={ dataCategoriesResume.avg_time } 
                            avgTimeAnswer={texts.avgTimeAnswer}
                            avgCloseTime={texts.avgCloseTime}
                        />
                        <Row className='mb-4'>
                            <Col xs={12}>
                            <Card className="p-3 text-center">
                                <div>
                                    <p className="d-inline mx-3 emigre-bold text-secondary">{texts.avgCloseTime}<span className="legend time-answered"></span></p>
                                    <p className="d-inline mx-3 emigre-bold text-secondary">{texts.avgTimeAnswer} <span className="legend other-answered"></span></p>
                                </div>
                                
                                <TimeClosingAvg 
                                    monthsLabel={monthsLabel} 
                                    dataCategoriesResume={dataCategoriesResume}
                                    label1={texts.label1}
                                    label2={texts.label2} 
                                
                                />                               
                                {/* <HistoricalChart monthsLabel={monthsLabel} dataIncidences={dataIncidences}/> */}
                            </Card>
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
            </Container> 
        )
    }

export default HistoricalTotal