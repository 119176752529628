import React, { useEffect, useState } from "react"
import { contextApp } from './contextApp.jsx'
import notificationImage from '../../assets/img/n.jpg'
const MainWrapper = (props) => {

    useEffect(()=>{
      if (!("Notification" in window)) {
          console.log("This browser does not support desktop notification");
      } else {
          Notification.requestPermission();
      }
    },[])

    const [currentLanguage] = useState('english');
    const [selectValue, setSelectValue] = useState({
      franchise: '',
      state: '',
      city: '',
      suc: '',
      months: '',
      monthsRange: '',
      monthsLabel: ''
    });

    const [counter, setCounter] = useState(0)

    const handlerCounter = (type, string) => {

      switch (type) {
        case 'increase':
          setCounter(counter + 1)
          const count = counter + 1
          document.title = count > 0 ? `(${count}) Little Caesars CRM` : 'Little Caesars CRM';
          
          var options = {
              body: "",
              icon: notificationImage,
              dir: "ltr"
          };
          
          new Notification(string, options)
            
      
          break;

        case 'clear':
          setCounter(0)
          document.title = 'Little Caesars CRM';
          break;

        default:
          break;
      }
    }
    return (
      <>
        <contextApp.Provider value={{currentLanguage, selectValue, setSelectValue, counter, setCounter, handlerCounter}}>
            <main>
                {props.children}
            </main> 
        </contextApp.Provider>
      </>
    );
}
export default MainWrapper