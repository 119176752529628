/* eslint-disable */
import React, {useEffect, useState, useContext} from 'react'
//import './TableCustomers.scss'
import { Container } from 'reactstrap';
import MaterialTable from 'material-table';
//import { ToastContainer, toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { contextApp } from "../../../App/contextApp";
import language from './language.json';
import Moment from 'react-moment';

const TableATC = (props) => {
    const { dataATC } = props;

    const {currentLanguage} = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }
    },[currentLanguage])
   
    let categories = {time:'Wait Time', service:'Service',others: 'Other', product:'Product'};
    const status = {'Open':'Open', 'In progress': 'In Progress', 'Resolved': 'Resolved'};

    const columns = [
            {field: "name", title: <div className='text-uppercase'><i className="icomoon icon-user_service mr-1" /> {texts.client}</div>, 
            render: rowData => {
                return <span>
                        {rowData.name}
                    </span>

            }
            }, 
            {field: "complaint_type", title: <div className='text-uppercase'><i className="icomoon icon-tag mr-1" /> {texts.category} </div>, lookup: categories,
                render: rowData => {
                    return <span>{rowData.complaint_type === 'time' ? 'wait time': rowData.complaint_type}</span>

                }
            }, 
            {field: "created_at", title: <div className='text-uppercase'><i className="icomoon icon-date mr-1" /> {texts.date}</div>,
            render: rowData => {
                return <span>
                        <Moment format="YYYY/MM/DD hh:mm A">
                            {rowData.created_at}
                        </Moment> EST
                    </span>

            }
            }, 
            {field: "franchise", title: <div className='text-uppercase'><i className="icomoon icon-shop mr-1" /> {texts.franchise}</div>,
                render: rowData => {
                return <span>
                        {rowData.franchise}
                    </span>

            }
            },
            {field: "store", title: <div className='text-uppercase'><i className="icomoon icon-sucursal mr-1" /> {texts.store}</div>,
            render: rowData => {
                return <span>
                        {rowData.store}
                    </span>

            }
            }, 
            {field: "status", title: <div><i className="icomoon icon-edit mr-1" /> {texts.status}</div>, lookup: status,
            render: rowData => {
                return <span>
                        {rowData.status}
                    </span>

            }
            }
        ];
        
    const openChat = (selectedRow) => {
       
        props.history.push(`/atencion/chat/showconversationsinit/${selectedRow.id}/${selectedRow.ktbots_id}`);
    }

    if (dataATC.lenght > 0) {
        
        dataATC.map(item => {
            if(item.name.indexOf('handler') !== -1){
                item.name = "Not Available";
            }
        })

    } else {
        /* toast.warning(`SE PRODUJO UN ERROR POR FAVOR CONTACTE A SOPORTE ERROR 500`, {

            position: toast.POSITION.BOTTOM_RIGHT,

            autoClose: 8000

        });
 */

    }

    return(
        <>
            <Container className="customers">

                <MaterialTable 
                title=""
                columns={columns} 
                data={dataATC} 
                options={{
                    filtering: true, headerStyle: {
                    backgroundColor: '#424242',
                    color: '#FFF',
                    textAlign: 'center',
                    fontWeight: 'Bold'
                  }
                }}
                localization={{
                    body: {
                        emptyDataSourceMessage: texts.noMoreData,
                        filterRow: {
                            filterTooltip: 'Filter by'
                        }
                    },
                    toolbar: {
                      searchTooltip: texts.search,
                      searchPlaceholder: texts.search
                    },
                    pagination: {
                      labelRowsSelect: texts.labelRowsSelect,
                      labelDisplayedRows: ' {from}-{to} of {count}',
                      firstTooltip: texts.firstTooltip,
                      previousTooltip: texts.previousTooltip,
                      nextTooltip: texts.nextTooltip,
                      lastTooltip: texts.lastTooltip
                    }
                }}
                onRowClick = {((evt, selectedRow) => openChat(selectedRow))}
                
                />
            </Container>
        </>
    );
}

export default withRouter(TableATC)