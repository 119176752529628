import React,  { useState, useContext, useEffect } from 'react';
import { Container, Row, Col,  Card, CardTitle } from 'reactstrap';
import { contextApp } from '../../App/contextApp';
import logo from '../../../assets/img/logo-orange.png';
import './Login.scss';
import language from './language.json';

//?access=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjNiOTE4NTE5MzY1ZWI0MTM5MGE3YjA0MmExMjcxODViY2M2ZmQ3MGM1ZTE2N2JlZGM5MjNmNDZmNjZkNzY1Y2I4MzllOGNlYTc0MThiYWZhIn0.eyJhdWQiOiIxOSIsImp0aSI6IjNiOTE4NTE5MzY1ZWI0MTM5MGE3YjA0MmExMjcxODViY2M2ZmQ3MGM1ZTE2N2JlZGM5MjNmNDZmNjZkNzY1Y2I4MzllOGNlYTc0MThiYWZhIiwiaWF0IjoxNjQxNDgzOTc5LCJuYmYiOjE2NDE0ODM5NzksImV4cCI6MTY3MzAxOTk3OSwic3ViIjoiMSIsInNjb3BlcyI6W119.VhaMIVUO9y8227Zd8cnZxSKYNAl6QtGdxJou-8DvqHIGu1sx9M9pFvBd0cPDQVTnwovM_izDTyhq2u8szawu_aRyiPqcgEPnTwHzeQgzRvRNDy1Mld3yUKgIgwpxKm2N-rxo6iLRhk7DwgoshY5LlfcZu6LVtA19ctWmMTPPHhE38ZtyuDawrjQvRkVDEvbzfK-jvjXRHNbGIc6Adg4NRFJltuPp3lxqoW_o1dFk4U2fEnqHg7_ueZl55pJNia2IVUHBInEZz1mKpK8oogSIQlkSGSFRZhCWWFnqcaOQEp31uHgstsj32lZ28Np4s61OQL_zpWyJ4RN-TX0Evb-vQv2XPU9uh6vpt56VOS3eW0MyZXQxBhfFhRrrrTrCbvWvX1mMcl-f5SyWEiIvsdSAIH5oUKwU340d3Y4XOqo0IRicYw7iALEvZbS6uiJ3emPl6xu1JcCStMYrQkbm-w-MgvuTsSlhEIk31syzs8SgGDe1NttQBynMZ1gnHx6X3gWGB8BE5K8JCxFZOHPH1DWRXYyPo_4fgorojYtPckd4eWD3cO92VFvAI3IRL9nKU01R5SVU5rAoq_ozrwwrtsmnXTVSZoDcEsfGl7onN3xN0Bn_XAAATIYNI8Qi45zGdBvZx68WFFkuVhf44jqh3kgyLgNXQdO4-oZr10kdYWknNFo

const Login = (props) => {

    const {currentLanguage} = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }


        if(localStorage.getItem(process.env.REACT_APP_SECRET_LOCAL_KEY)) {
            
            const url = new URL(window.location.href);
        
            const access = url.searchParams.get("Token");

            if(access) {
                const data = { access_token: access }
    
                localStorage.setItem(process.env.REACT_APP_SECRET_LOCAL_KEY, JSON.stringify(data));

            }


            props.history.push(`/home`);

            return;

        } 
            
        login()
        // eslint-disable-next-line
    },[currentLanguage])
    
    
    const login = () => {
        
        const url = new URL(window.location.href);
        
        const access = url.searchParams.get("Token");

        if(!access) {

            window.location.href = process.env.REACT_APP_LOGIN_DOMAIN;

            return null;
        } 

        const data = { access_token: access }

        localStorage.setItem(process.env.REACT_APP_SECRET_LOCAL_KEY, JSON.stringify(data));
        
        props.history.push(`/home`);

    }

    return (
        <Container className="login">
            <Row>
                <Col>
                    <Card className="mt-5 p-3">
                        <CardTitle align="center">
                            <img src={logo} className="img-fluid mb-3 mt-5" alt="Little Caesars"/>
                            <h4 className="text-dark emigre-bold text-uppercase">{texts?.welcome}</h4>
                        </CardTitle>
                    </Card>
                </Col>
            </Row>
        </Container>
    )

}

export default Login;