import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Form, FormGroup, Input } from 'reactstrap';
import Layout from "../layout/layout";
import ChatViewer from "./components/ChatViewer";
import Ticket from "./components/Ticket";
import TicketDetail from "./components/TicketDetail";
import './styles.scss';
import HttpHandlerServices from '../../services/httpService';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { toast } from 'react-toastify';
import { contextApp } from '../App/contextApp'
//eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImFhMDJmMTI1ZGY1MDk1MzM5MDk1ZjdkNTk0MDQyY2Y4NWExNjU0NDhiZWE0Y2FlNjIzNzE3YWZhNTFiNjg1MmUzZDZiYjRlOTgyYmNmN2QxIn0.eyJhdWQiOiIxOSIsImp0aSI6ImFhMDJmMTI1ZGY1MDk1MzM5MDk1ZjdkNTk0MDQyY2Y4NWExNjU0NDhiZWE0Y2FlNjIzNzE3YWZhNTFiNjg1MmUzZDZiYjRlOTgyYmNmN2QxIiwiaWF0IjoxNjQzNjQ0OTg3LCJuYmYiOjE2NDM2NDQ5ODcsImV4cCI6MTY3NTE4MDk4Niwic3ViIjoiMiIsInNjb3BlcyI6W119.CQLeT6t6lUuD73L85hqUNhnzHjTQwXiIHdZB6W7Ot0yDJYvW6olztxJyEvNPXx0eKD9icD4DCjEVtjiZrXoYZQJQpYd8xYp8VaRvYDODuPlBkJ2-BYk61zFvvip_-tflGiHi0qrG-UzHnEFSREy5Mnzi0w9TrOqhMZYP4z7c1sibXe0GwkRiNhzDkZ-8mK1MrI7k3gPaLCDB_uJq-u7HYdJD2Y0gZqwfT8Kk4ClhXKS-s-AALuGlrXdSMCSbcdug1f2Gxqg88QnJjhkHgqLpZVrDD2g1wdEtBSo5wJ76S1-vhzQuxCKGvPXZGSmypB54TZ9NjawSN6NCYAHpRWkKYTFAMPwSshm25x0FftqHnaeVLIwk1OaCh3pBDUJaqqKIc2p9Pf9s2tsiPjishFsfJeiJvLddUS8oji-Ze_MUeyanPjQM4F2vNZuv4WoHymBEIbh8PbYa0LRNddXF_EBmSYCTMU2KJUOvHJVb1HPsix2pkCJCPXhkbZoS8LBQmjKG2KhTG-sEM56TA1XFFIvXuqwFrLptsVF_mCS8l1pa06pzQo-wsddbPERru5yMZwwDbc6sJ5A28ajvhvW2s9hqWRDSD-CAEodo2_1fK0cWhyJL6W9Z6frpP0rLMFATrajHlBDj_al1zTNsxWrudjZQujAAuahOhmE8kE--hjb1ei0

const SupportCenter = () =>{

    const { handlerCounter } = useContext(contextApp);

    const [tickets, setTickets] = useState([]);

    const [ticketsFocus, setTicketsFocus] = useState('');

    const [ticketsFilter, setTicketsFilters] = useState([]);

    const [currentChat, setCurrenChat] = useState(null);

    const [client, setClient] = useState(null);

    const [wsState] = useState(null);

    const [channel] = useState("newmessage")

    const [textFilter, setTextFiler] = useState('')

    const [newMessage, setNewMessage] = useState(null)

    const [detailsInfo, setDetailsInfo] = useState(null)

    const [userData, setUserData] = useState(null);

    const [noti, setNoti] = useState(false)

    const [count, setCount] = useState(0)

    useEffect(() => { 

        getUserData()
        getOpenTickets()
        // eslint-disable-next-line
    },[])

    const getUserData = () => {

        const endpoint = '/user';

        const httpHandlerServices = new HttpHandlerServices();

        httpHandlerServices.getMethod(endpoint).then(

            async res => {

                const data = await res;

                setUserData(data);

            }
        );
    }


    useEffect(()=> {
        
        if(client) {
           
            ticketsWsHandler()
        }
        // eslint-disable-next-line
    },[client, wsState]);


    useEffect(()=> {
        
        if(!client) {

            setClient(new W3CWebSocket(process.env.REACT_APP_WS))
            return
        }
    },[client])


    const ticketsWsHandler = () => {

        client.onerror = function(e) {
            
            toast.error(`WEBSOCKET SEEMS DOWN, please try again later, if the problem persist contact technical support`
            , {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 8000
            });
        };
        
        client.onopen = function() {
        
            function subscribe() {

                if (client.readyState === client.OPEN) {
                    
                    client.send(`{"command" : "subscribe", "channel" : "${channel}"}`)
                
                }
            }
            
            subscribe();
            
        };
        
        client.onmessage = function(e) {

            if (typeof e.data === 'string') {

                const res = JSON.parse(e.data);

                setNewMessage(res)
            
                setCount(count + 1)

                setNoti(true)


            } 
        };

        client.onclose = function() {

            setClient(new W3CWebSocket(process.env.REACT_APP_WS))

        };

    } 
   
    const getOpenTickets = () => {

        const endpoint = '/showconversationsopened'

        const httpHandlerServices =  new HttpHandlerServices();

        const data = {
            "franchise_ids" : "0",
            "state_ids" : "0",
            "locality_ids" : "0",
            "stores_ids" : "0"
        }

        httpHandlerServices.postMethod(endpoint,data).then(
            async (res) => {
                const data = await res;
    
                if(!data.exception) {

                    setTickets(data)

                    setTicketsFilters(data)

                }

            },
            async (err) => {
                const error = await err;
                if(error) {
                    toast.error(`SOMETHING WRONG HAPPENED, if the problem presist contact technical support`
                    , {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 8000
                    });
                }
            }
        )
    }

    const startChat = (info) => {
        setCurrenChat(info)
    }

    const makeFilter = (string) => {

        var filter = {
            name: string,
            franchise: string,
            message: string,
            complaint_type: string,
            created_at: string,
            status: string,
            store: string
        }

        const filtered = tickets.filter( ticket => {

            for(let key in filter) {
                if(ticket[key] && ticket[key].toLowerCase().includes(string)) {
                    return true;
                }
            }
            return false
        })

        setTicketsFilters(filtered)
        
    }


    useEffect(()=>{

        if(newMessage) {
            const filterList = tickets.filter(item => item.id !== parseInt(newMessage.id))
            const newArr = [newMessage]
            const found = userData.stores.find(ele => ele === parseInt(newMessage.store_id)) // 3896  2203
            if(newMessage.status_id !== 3) {
                
                if(found) {
                    const newList = newArr.concat(filterList)
                    setTickets(newList)
                    if(noti) {
                        if(userData.stores){
                            const string = `${newMessage.name.toUpperCase()}: ${newMessage.message}`
                            handlerCounter('increase', string)
                            setNoti(false)
                        }
                    }
                }
                
                

            } else {

                setTickets(filterList)

            }
    
        }
        makeFilter(textFilter)
         // eslint-disable-next-line
    },[tickets, textFilter, newMessage])

    useEffect(()=>{

        setTimeout(()=>{
            if(newMessage) {
                const ele = document.getElementById(newMessage.id)
                if(ele) {
                    ele.classList.add('newmessage')
                }
                setNewMessage(null)
                return
            }

        },80)
        // eslint-disable-next-line
    },[ticketsFilter])

    const setFocusOn = (data) => {
        handlerCounter('clear', null)
        setTicketsFocus(data.id)
    }

    const closeChat = () => {
        setCurrenChat(null)
        setTicketsFocus('')
        getOpenTickets()

    }

    useEffect(()=>{

        if(ticketsFilter.length > 0) {
            const focused =  document.querySelectorAll('.ticket')
            focused.forEach(t => {
                if(parseInt(t.id) === ticketsFocus){
                    t.classList.remove('newmessage')
                    t.classList.add('active')
               
                } else {
                    t.classList.remove('active')
                }
            })
        }
       
    }, [ticketsFocus, ticketsFilter])

    const chatInfo = (info) => {
        setDetailsInfo(info?.detail)
    }

    return (
        <Layout>
            <div className="main-wrap">
                <div className="content">
                    <Container fluid>
                        <Row>
                            <Col className="complaints-list-container">
                                <div className="search-box">
                                    <span className="material-icons MuiIcon-root MuiIcon-fontSizeSmall" aria-hidden="true">search</span>
                                    <Form>
                                        <FormGroup>
                                            <Input type="text" name="search" id="search" placeholder="SEARCH" onChange={(e)=> setTextFiler(e.target.value)}/>
                                        </FormGroup>
                                    </Form>
                                </div>
                                <div className="complaints-list">
                                    {
                                        ticketsFilter?.map((ticket, i) => (
                                            <Ticket key={ticket.id + `${i}`} info={ticket} startChat={startChat} ticketsFocus={ticketsFocus} setFocusOn={setFocusOn}/>
                                        ))
                                    }
                        
                                </div>
                            </Col>
                            <Col xs={6} className="chat-container">
                                <div className="chat-viewer">
                                    { currentChat && <ChatViewer current={currentChat} chatInfo={chatInfo}/> } 
                                </div>
                            </Col>
                            <Col>
                                <div className='ticketdetail'>
                                    { currentChat && <TicketDetail current={currentChat} closeChat={closeChat} detailsInfo={detailsInfo}/> } 
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </Layout>
    );
}

export default SupportCenter;