import React from 'react'
import Moment from 'react-moment';
import StopLight from './StopLight';
import 'moment-timezone';
import moment from 'moment';

const Ticket = (props) => {

    const {info, startChat, setFocusOn } = props;

    const setCurrent = () => {
        startChat(info);
        setFocusOn(info);
    };

    const timeZoneTime = () => {

        const time = info.created_at
        const a = moment.tz(time, process.env.REACT_APP_TIMEZONE);
        return a
    }
    
    
    return(
        <div id={info.id} className={ "ticket" } onClick={() => setCurrent()}>
            <div className="header">
                <div className='head'>
                    <div>
                        <Moment format="YYYY/MM/DD" >
                            {info.created_at}
                        </Moment>
                        <span> </span> 
                        <Moment fromNow ago>
                            {timeZoneTime()}
                        </Moment>
                        
                    </div>
                    <span>
                        <StopLight value={info.created_at} />
                    </span>
                </div>
                <div className="info-container">
                    <div className="client-name">{info.name}</div>
                    <div className="tags">
                        <span className='tag'>
                            <i className="icomoon icon-tag"></i> {info.complaint_type}
                        </span>
                    </div>
                </div>
            </div>
            <div className="preview">
                <p>
                    {info.message?.slice(0, 20)}...
                </p>
            </div>
        </div>
    )
}

export default Ticket