import React from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';

const ProtectedRoute = ({component: Component, ...rest}) => {
   
    return (
        <Route 
        {...rest} 
        render= {
            (props => {
            
                const userSession = localStorage.getItem(process.env.REACT_APP_SECRET_LOCAL_KEY) ? JSON.parse(localStorage.getItem(process.env.REACT_APP_SECRET_LOCAL_KEY)) : {};

                if (userSession.access_token) {
                    return <Component {...props} />
                } else {
                    return <Redirect to = {
                        {
                            pathname: "/logout",
                            state: {
                                from: props.location
                            }
                        }
                    }/>
                }
            })
        }/>
    );
}

export default withRouter(ProtectedRoute);