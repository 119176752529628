import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Input } from "reactstrap"
import HttpHandlerServices from '../../../services/httpService';
import { toast } from 'react-toastify';

const TicketDetail = (props) => {
    
    const {current, closeChat, detailsInfo} = props;

    
    
    const [details, setDetails] = useState({
        email:'',
        phone:'',
        notes:''
    })

    const saveNoteInfo = () => {

        const endpoint = `/conversation/notes/save`;

        const httpHandlerServices =  new HttpHandlerServices();

        const data = {
            ktbots_id : current.ktbots_id,
            notes: details.notes
        }

        httpHandlerServices.postMethod(endpoint, data).then(
            async (res) => {
                const data = await res;
                if(data) {
                    toast.error(`the note was saved`
                        , {
        
                            position: toast.POSITION.BOTTOM_RIGHT,
        
                            autoClose: 1000
        
                        });
                }
                
            },
            async (err) => {
                const error = await err;
                if(error) {
                    toast.error(`something went wrong, please try again later`
                        , {
        
                            position: toast.POSITION.BOTTOM_RIGHT,
        
                            autoClose: 5000
        
                    });

                }
            }
        )
    }

    const closeCoversation = () => {

        const endpoint = `/endconversation/${current.ktbots_id}`;
        
        const httpHandlerServices =  new HttpHandlerServices();
        
        httpHandlerServices.getMethod(endpoint).then(
            async (res) => {
                const data = await res;
                if(data) {
                    closeChat()
                }
            },
            async (err) => {
                const error = await err;
                
            }
        )
    }

    useEffect(()=> {
        if(detailsInfo) {
            setDetails({
                email: detailsInfo.email,
                phone: detailsInfo.phone,
                notes: detailsInfo.notes
            })
        }
        /* eslint-disable-next-line */
    },[detailsInfo])

    useEffect(()=> {
       
        setDetails({
            email: 'loading...',
            phone: 'loading...',
            notes: 'loading...'
        })
           
        /* eslint-disable-next-line */
    },[current])

    return(
        <div className='ticketdetail'>
            <div className='header'>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h3>DETAILS</h3>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='body-ticket pt-4'>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h1><i className="icomoon icon-mail mr-2"></i>CUSTOMER EMAIL:</h1>
                            <p>{details?.email}</p>
                        </Col>
                        <Col xs={12}>
                            <h1><i className="icomoon icon-cel mr-2"></i>CUSTOMER PHONE NUMBER:</h1>
                            <p>{details?.phone}</p>
                        </Col>
                        <Col xs={12}>
                            <h1><i className="icomoon icon-sucursal"></i> STATUS:</h1>
                            <p>{current.status}</p>
                        </Col>
                        <Col xs={12}>
                            <h1><i className="icomoon icon-shop mr-2"></i> STORE ID:</h1>
                            <p>{current.store}</p>
                        </Col>
                        <Col xs={12}>
                            <h1><i className="icomoon icon-tag mr-2"></i> NOTES:</h1>
                            
                            <Input 
                            type="textarea" 
                            name="text" 
                            id="exampleText" 
                            rows="8"
                            value={details?.notes} 
                            onChange={(e)=>{setDetails({...details, notes: e.target.value})}}/>
                            
                        </Col>
                        
                    </Row>
                </Container>
            </div>
            <div className='mt-4 mb-3'>
                <Container>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-center flex-column flex-md-row">
                            <Button 
                            color="primary" 
                            className="rounded-0 px-5 mb-3 mr-md-3 text-uppercase emigre-bold order-md-1 order-2"
                            block
                            onClick={saveNoteInfo}
                            >
                            SAVE NOTES
                            </Button>
                        </Col>

                        <Col xs={12} className="d-flex justify-content-center flex-column flex-md-row">
                            <Button 
                            color="primary" 
                            className="rounded-0 px-5 mb-3 mr-md-3 text-uppercase emigre-bold order-md-1 order-2"
                            block
                            onClick={closeCoversation}
                            >
                            CLOSE TICKET
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default TicketDetail