import React from 'react'
import { Row, Col, Card, Badge } from 'reactstrap'

const TimeAverage = (props) =>{
    const { avg_close, avg_time, avgTimeAnswer,avgCloseTime } = props;

   return(
           <Row className="mb-5">
           
               <Col xs={12} md={6} tag="article">
                    <Card className="p-4">
                        <h5 className="text-secondary emigre-bold text-uppercase m-0">{avgTimeAnswer}<Badge color='primary' className='badge-line text-white py-2 px-3 ml-4 text-lowercase'>{avg_time}</Badge></h5>
                    </Card>
               </Col>
               <Col xs={12} md={6} tag="article">
                    <Card className="p-4">
                        <h5 className="text-secondary emigre-bold text-uppercase m-0">{avgCloseTime} <Badge color='primary' className='badge-line text-white py-2 px-3 ml-4 text-lowercase'>{avg_close}</Badge></h5>
                    </Card>
               </Col>
           </Row>
     )

}

export default TimeAverage;