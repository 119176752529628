import React from "react"
import { Pie } from 'react-chartjs-2'
import { Row, Col } from 'reactstrap'
import './pieChart.scss';

const PieChart = (props) =>{
    
    const { dataCategoriesResume, texts } = props;
    const { categories } = dataCategoriesResume;
    
    let data = {};
    
    let percentDataCat = [];
    
    if(categories){
    
        let dataCatTotal = categories.reduce((a,b) => a+b);

        if(dataCatTotal === 0) dataCatTotal = 1

        percentDataCat = categories.map(el => parseInt((100 * el)/dataCatTotal));

        data = {
            labels: [texts?.service, texts?.product, texts?.time, texts?.other],
            datasets: [
                {  
                    label: '%',
                    backgroundColor: ['#f9cb11', '#29bff0', '#99de14', '#ff7534'],
                    data: percentDataCat,
                }
            ] 
        }

    }

    return( <>
                <Row className="mb-4">
                    <Col xs={12} className="text-center">
                        <h5>{texts.totalIncidents}</h5>
                    </Col>
                    <Col xs={12} md={4} className="text-right">
                        <p className="number formatext">{texts.service} <span className="legend service-answered"></span><span className="grayCapsule w-auto ml-1 d-inline-block">{percentDataCat && percentDataCat[0]}%</span></p>
                        <p className="">{texts.product} <span className="legend product-answered"></span><span className="grayCapsule w-auto ml-1 d-inline-block">{percentDataCat && percentDataCat[1]}%</span></p>
                        <p className="">{texts.time} <span className="legend time-answered"></span><span className="grayCapsule w-auto ml-1 d-inline-block">{percentDataCat && percentDataCat[2]}%</span></p>
                        <p className="">{texts.other} <span className="legend other-answered"></span><span className="grayCapsule w-auto ml-1 d-inline-block">{percentDataCat && percentDataCat[3]}%</span></p>
                    </Col>
                    <Col xs={12} md={8}>
                        <Pie data={data} legend={{display:false}}></Pie>
                    </Col>
                </Row>
            </>
        )
}

export default PieChart;