/* eslint-disable */
import React from 'react'
import { Row, Col } from 'reactstrap'
import { Doughnut } from 'react-chartjs-2'
import './DougnutCharts.scss'

const DoughnutCharts = (props) => {

    const { product, service, time, other, texts} = props;
    
    let dataProduct = {};
    let dataService = {};
    let dataTime = {};
    let dataOther = {};

    if(product && product.length > 0){
        let Answered = 0;
        let Unanswered = 0;
        product.map(value => {
            if(value.status==="Resolved"){
                Answered = parseInt(value.total);              
            }
            if(value.status==="Open"){
                Unanswered = parseInt(value.total);                    
            }})
        dataProduct = {
            labels: [ texts.answered, texts.notAnswered],
            datasets: [{
                data: [Answered, Unanswered],
                backgroundColor: ['#29bff0','#eaebf2'] //blue
            }]
        };
    }
    if(service && service.length > 0){
        let Answered = 0;
        let Unanswered = 0;
        service.map(value => {
        if(value.status==="Resolved"){
            Answered = parseInt(value.total);              
        }
        if(value.status==="Open"){
            Unanswered = parseInt(value.total);                    
        }})
        dataService = {
            labels: [texts.answered, texts.notAnswered],
            datasets: [{
                data: [Answered, Unanswered],
                backgroundColor: ['#f9cb11', '#eaebf2'],//yellow
            }]
        };
    }
    if(time && time[0]){
        let Answered = 0;
        let Unanswered = 0;
        time.map(value => {
        if(value.status==="Resolved"){
            Answered = parseInt(value.total);              
        }
        if(value.status==="Open"){
            Unanswered = parseInt(value.total);                    
        }})
        dataTime = {
            labels: [ texts.answered, texts.notAnswered],
            datasets: [{
                data: [Answered, Unanswered],
                backgroundColor: ['#99de14', '#eaebf2'] //green
            }]
        };
    }
    if(other && other[0]){
        let Answered = 0;
        let Unanswered = 0;
        other.map(value => {
        if(value.status==="Resolved"){
            Answered = parseInt(value.total);              
        }
        if(value.status==="Open"){
            Unanswered = parseInt(value.total);                    
        }})
        dataOther = {
            labels: [ texts.answered, texts.notAnswered],
            datasets: [{
                data: [Answered, Unanswered],
                backgroundColor: ['#ff7534', '#eaebf2'], //orange
            }]
        };
    }

    return (
        <>
            <Row>
                <Col xs={12} md={6} className="flex-initial mb-5 ">
                    <p className="text-uppercase d-block text-center m-0">{texts.product}</p>
                    <Row>
                        {product && product[0] && <Col xs={6} className="legends-doughnuts text-right p-0 flex-column justify-content-center d-flex">
                                <p className="text-uppercase m-0">{texts.answered} <span className="legend product-answered"></span></p>
                                <p className="text-uppercase m-0">{texts.notAnswered} <span className="legend no-answered"></span></p>   
                            </Col> 
                        }
                        <Col xs={6} className='d-flex justify-content-center align-items-center p-0'>
                            {product && product[0] ?
                                <Doughnut data={dataProduct}/>
                                : texts.noData
                            }
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} md={6} className="mb-5">
                    <p className="text-uppercase d-block text-center m-0">{texts.service}</p>
                    <Row>
                        { service && service[0] &&
                            <Col xs={6} className="legends-doughnuts text-left p-0 text-right flex-column justify-content-center d-flex">
                            <p className="text-uppercase m-0"><span className="legend service-answered"></span> {texts.answered}</p>
                            <p className="text-uppercase m-0"><span className="legend no-answered"></span> {texts.notAnswered}</p>
                            </Col>
                        }
                        <Col xs={6} className='d-flex justify-content-center align-items-center p-0'>
                        
                            { service && service[0] ?
                                <Doughnut data={dataService}/>
                                : texts.noData
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6} className="mb-5">
                    <p className="text-uppercase d-block text-center m-0">{texts.time}</p>
                    <Row>
                    {time && time[0] &&
                        <Col xs={6} className="legends-doughnuts text-right p-0 flex-column justify-content-center d-flex">
                            <p className="text-uppercase m-0"> {texts.answered}<span className="legend time-answered"></span></p>
                            <p className="text-uppercase m-0"> {texts.notAnswered}<span className="legend no-answered"></span></p>
                        </Col> 
                    }
                    <Col xs={6}className='d-flex justify-content-center align-items-center p-0'>
                        { time && time[0] ?
                            <Doughnut data={dataTime}/>
                            : texts.noData
                        }
                    </Col>
                    </Row>
                </Col>
                <Col xs={12} md={6} className="mb-5">
                    <p className="text-uppercase d-block text-center m-0">{texts.other}</p>
                    <Row>
                    { other && other[0] &&
                        <Col xs={6} className="legends-doughnuts text-right text-left flex-column justify-content-center d-flex">
                        <p className="text-uppercase m-0"><span className="legend other-answered"></span> {texts.answered}</p>
                        <p className="text-uppercase m-0"><span className="legend no-answered"></span> {texts.notAnswered}</p>
                        </Col>}
                        <Col xs={6} className='d-flex justify-content-center text-right align-items-center p-0'>
                            { other && other[0] ?
                                <Doughnut data={dataOther}/>
                                : texts.noData
                            }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default DoughnutCharts;
