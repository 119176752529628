/* eslint-disable */
import React, {useState, useEffect} from 'react';
import './Services.scss';
import Layout from '../../layout/layout';
import TableATC from './TableATC/TableATC';
import { Container, Row, Col, Card } from 'reactstrap';
import Select from 'react-select';
import HttpHandlerServices from '../../../services/httpService';
import language from './language.json';

const Services = (props) =>{

    const [dataATC, setDataATC] = useState([]);

    const [optionsFran, setOptionsFran] = useState([]);

    const [optionsStates, setOptionsStates] = useState([]);

    const [optionsCities, setOptionsCities]= useState([]);

    const [optionsSuc, setOptionsSuc] = useState([]);

    const [sendData, setSendData] = useState({
            franchise_ids: '0',
            state_ids: '0',
            locality_ids: '0',
            stores_ids: '0'
    })

    const [texts] = useState(language.english);

    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? null
              : isSelected
              ? data.color
              : isFocused
              ? '#ff7534'
              : null,
            color: isDisabled
              ? 'white'
              : isSelected,
            cursor: isDisabled ? 'not-allowed' : 'white',
      
            ':active': {
              ...styles[':active'],
              backgroundColor: !isDisabled && (isSelected ? 'white' : '#ff7534'),
            },
          };
        },
        multiValue: (styles, { data }) => {
          return {
            ...styles,
            backgroundColor:'#ff7534',
          };
        },
        multiValueLabel: (styles, { data }) => ({
          ...styles,
          color: 'white',
        }),
        multiValueRemove: (styles, { data }) => ({
          ...styles,
          color: 'white',
          ':hover': {
            backgroundColor: data.color,
            color: '#dc3545',
          },
        }),
    };

    const sendFiltersATC = () => {

        const httpHandlerServices = new HttpHandlerServices();

        const getDataATC = '/showconversations';
       
        httpHandlerServices.postMethod(getDataATC, sendData).then(
            async(res) => {

                const data = await res;

                if(data.message){

                    setDataATC([]);

                }

                setDataATC(data);

            }

        );

    }

    const getFranquises = () => {

        const endpoit = '/showfranchise';

        const httpHandlerServices = new HttpHandlerServices();

        httpHandlerServices.getMethod(endpoit).then(

            async res => {

                const data = await res;

                const array = [];

                data.map(item => {

                    const obj = {
                        value: item.id,
                        label: item.franchise
                    }

                    array.push(obj)
                    
                });

                setOptionsFran(array);
                
            },
            async error => {
                const err =  await error;
                if(err) {
                    toast.error(`SOMETHING WRONG HAPPEND, please try again later, if the problem presist contact technical support`
                    , {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        autoClose: 8000
                    });
                }
            }  
        );
    }

    const getStatesList = () => {
        const httpHandlerServices = new HttpHandlerServices();
        const endpoint = `/showstate`;
        const data = {
            franchise_ids: 0,
            state_ids: 0,
            locality_ids: 0,
            stores_ids: 0,
          }
        httpHandlerServices.postMethod(endpoint, data).then(
            async(res) => {
                const data = await res;
             
                if(data){
                    const array = []
                    data.map(ele => {
                        const item = {
                            label: ele.state,
                            value: ele.id
                        }
                        array.push(item);
                    });
                    setOptionsStates(array);
                }
                
            }, 
            (error) => {

            }
        )
    }

    const getLocalityList = () => {
        const httpHandlerServices = new HttpHandlerServices();
        const url = `/showlocality`;
        const data = {
            franchise_ids: 0,
            state_ids: 0,
            locality_ids: 0,
            stores_ids: 0,
        }
        httpHandlerServices.postMethod(url, data).then(
            async(res) => {
                const data = await res;
    
                if(data){
                    const array = []
                    data.map(ele => {
                        const item = {
                            label: ele.locality,
                            value: ele.id
                        }
                        array.push(item);
                    });
                    setOptionsCities(array);
                }
                
            }, 
            (error) => {

            }
        )
    }

    const getStores = () => {
        const httpHandlerServices = new HttpHandlerServices();
        const url = `/showstoresfull`;
        const data = {
            franchise_ids: 0,
            state_ids: 0,
            locality_ids: 0,
            stores_ids: 0,
        }

        httpHandlerServices.postMethod(url, data).then(
            async(res) => {
                const data = await res;
                if(data){
                    const array = []
                    data.map(ele => {
                        const item = {
                            label: ele.name,
                            value: ele.id
                        }
                        array.push(item);
                    });
                    setOptionsSuc(array);
                }
                
            }, 
            (error) => {

            }
        )
    }
    
    const handleChange = (event, name) => {
        const search = [];
        if(event !== null){
            event.map(item => search.push(item.value))
            if(name === 'franchise_ids') {
                getStores();
                setSendData({...sendData, franchise_ids: search.join()})
            }
            if(name === 'state_ids') {
                
                setSendData({...sendData, state_ids: search.join()})
            }
            if(name === 'locality_ids') setSendData({...sendData, locality_ids: search.join()})
            if(name === 'stores_ids') {
                setSucValue(event.value)
                setSendData({...sendData, stores_ids: search.join()})
            }
        }else{
            /* setSendData({...sendData,...sendData[name] = '0'}) */
            if(name === 'franchise_ids') {
                setSucValue(null)
                setSendData({...sendData, franchise_ids: '0', stores_ids: '0'})
            }
            if(name === 'state_ids') setSendData({...sendData, state_ids: '0'})
            if(name === 'locality_ids') setSendData({...sendData, locality_ids: '0'})
            if(name === 'stores_ids') setSendData({...sendData, stores_ids: '0'})

        }
    }

    useEffect(()=>{
        sendFiltersATC();
        getFranquises();
        getStatesList();
        getLocalityList();
        //getStores();
    
    },[sendData])

    const [sucValue, setSucValue] = useState(null)

    return (
    <Layout>
        <div className="services">
            <Container>
                <h2 className="emigre-bold mt-5">{texts.customerSupport}</h2>
            </Container>

            <Container tag="section" className="filters mb-5">
            <Card body tag="article" className="d-block z-indez-99">
                <Row xs={12}>
                    <Col xs={12} md={3} className="mb-2">
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder={texts.filterByFranchise}
                            name="franchise" 
                            id="franchise"
                            closeMenuOnSelect={true}
                            isMulti
                            options={optionsFran}
                            noOptionsMessage={() => texts.noMoreData}
                            styles={colourStyles}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ff7534',
                                  primary: '#ff7534',
                                  neutral90: 'white',
                                  primary50:"#ff7534",
                                },
                              })}
                            onChange={(e)=>{
                                handleChange(e, 'franchise_ids');  
                            }}
                            />
                    </Col>
                    <Col xs={12} md={3} className="mb-2">
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="sucursal" 
                            id="sucursal"
                            placeholder={texts.filterByBranch}
                            closeMenuOnSelect={true}
                            noOptionsMessage={() => texts.noMoreData}
                            isMulti
                            options={optionsSuc}
                            styles={colourStyles}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                ...theme.colors,
                                primary25: '#ff7534',
                                primary: '#ff7534',
                                neutral90: 'white',
                                primary50:"#ff7534",
                                },
                            })}
                            value={sucValue}
                            isDisabled={optionsSuc.length < 1}
                            onChange={(e)=> handleChange(e, 'stores_ids')}
                            />
                    </Col>

                    <Col xs={12} md={3} className="mb-2"> 
                    <Select
                            className="basic-single"
                            classNamePrefix="select"
                            placeholder={texts.filterByState}
                            closeMenuOnSelect={true}
                            name="state" 
                            id="state"
                            isMulti
                            options={optionsStates}
                            styles={colourStyles}
                            noOptionsMessage={() => texts.noMoreData}
                            theme={theme => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary25: '#ff7534',
                                  primary: '#ff7534',
                                  neutral90: 'white',
                                  primary50:"#ff7534",
                                },
                              })}
                            onChange={(e)=> {
                                handleChange(e, 'state_ids');
                            }}
                            />
                    </Col>
                    <Col xs={12} md={3} className="mb-2">
                    <Select
                            className="basic-single"
                            classNamePrefix="select"
                            name="city" 
                            id="city"
                            placeholder={texts.filterByCity}
                            closeMenuOnSelect={true}
                            isMulti
                            noOptionsMessage={() => texts.noMoreData}
                            options={optionsCities}
                            styles={colourStyles}
                            theme={theme => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                primary25: '#ff7534',
                                primary: '#ff7534',
                                neutral90: 'white',
                                primary50:"#ff7534",
                              },
                            })}
                            onChange={(e)=> handleChange(e, 'locality_ids')}
                            />
                    </Col>
                   
                </Row>
          
            </Card>
        </Container>
            <TableATC dataATC={dataATC}/>
        </div>

    </Layout>)
}

export default Services; 