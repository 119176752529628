import React, { useState, useEffect, useContext } from "react";
import './Panel.scss';
import moment from 'moment';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import MaterialTable from 'material-table';
import { contextApp } from '../../App/contextApp'
import HttpHandlerServices from "../../../services/httpService";
import DatePicker from '../../DatePicker/DatePicker';
import Filters from '../../Filters/Filters';
import Period from './Period/Period';
import HistoricalTotal from './HistoricalTotal/HistoricalTotal';
import IncidencesByCategory from './IncidencesByCategory/IncidencesByCategory';
import PercentCategories from './PercentCategories/PercentCategories';
import ComplaintDetail from './ComplaintDetails/ComplaintDetail';
import Layout from '../../layout/layout';
import language from './language.json';


const Panel = () =>{
    const { currentLanguage } = useContext(contextApp);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{

        if(currentLanguage === 'spanish') {

            setTexts(language.spanish)

        }

    },[currentLanguage])
    
    const [requestData, setRequestData] = useState({
        franchise_id: "0",
        states: "0",
        cities: "0",
        stores: "0",
        months: `${moment().add(-3,'months').format('YYYY-MM')}, ${moment().format('YYYY-MM')}`
    })

    const [monthsRange, setMonthsRange] = useState(null);
    const [generalData, setGeneralData] = useState([]);
    const [dataCategoriesResume, setDataCategoriesResume ] = useState([]);
    const [dataIncidences, setDataIncidences] = useState([]);
    const [ dataCategories, setDataCategories ] = useState({
        product: 0,
        time: 0,
        service: 0,
        other: 0
    });


    const monthRangeHandler = (range) => {
        setMonthsRange(range);
    }

    const updateRequestDate = (data) => {
        setRequestData({...requestData, months: data})
    }


    const updateRequestData = (data, key) => {
        if(key === 'states' && data[0] === '0') {

            setRequestData({...requestData, [key]: data.join(), cities: "0"})
        } else {
            setRequestData({...requestData, [key]: data.join()})

        }
    }

   

    useEffect(()=>{

        const requestGeneralData = () => {
            
            const httpHandlerServices = new HttpHandlerServices();

            const endpointGeneralData = `/generalanalysis`;

            const dataGeneral = {
                franchise_id: requestData.franchise_id,
                states: requestData.states,
                cities: requestData.cities,
                stores: requestData.stores,
                months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
            }

            httpHandlerServices.postMethod(endpointGeneralData, dataGeneral).then(
                async(res) => {
                    const data = await res;
                   
                    setGeneralData(data);
                }
            );

            const getDataCategoriesEndPoint = '/showgeneral/categories';
            const dataCategories = {
                franchise_id: requestData.franchise_id,
                states: requestData.states,
                cities: requestData.cities,
                stores: requestData.stores,
                months: monthsRange.join()
            }

            httpHandlerServices.postMethod(getDataCategoriesEndPoint, dataCategories).then(

                async(res) => {
    
                    const data = await res;
                   
                    setDataCategoriesResume(data);
                    
                }
                
            );

            const getDataResumeIncidencesEndPoint = '/showgeneral/summary';
            const dataIncidencesResume = {
                franchise_id: requestData.franchise_id,
                states: requestData.states,
                cities: requestData.cities,
                stores: requestData.stores,
                months: monthsRange.join()
            }

            httpHandlerServices.postMethod(getDataResumeIncidencesEndPoint, dataIncidencesResume).then(
                
                async(res) => {
                    
                    const data = await res;
                    setDataIncidences(data);
                    
                }
            );

            const getDataByCategory = '/showgeneral/summarypay';
            const dataProducto = {
                franchise_id: requestData.franchise_id,
                states: requestData.states,
                cities: requestData.cities,
                stores: requestData.stores,
                filter: 'product',
                months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
            }
            const dataServicio = {
                franchise_id: requestData.franchise_id,
                states: requestData.states,
                cities: requestData.cities,
                stores: requestData.stores,
                filter: 'service',
                months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
            }
            const dataTiempo = {
                franchise_id: requestData.franchise_id,
                states: requestData.states,
                cities: requestData.cities,
                stores: requestData.stores,
                filter: 'time',
                months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
            }
            
            const dataOtros = {
                franchise_id: requestData.franchise_id,
                states: requestData.states,
                cities: requestData.cities,
                stores: requestData.stores,
                filter: 'others',
                months: `${monthsRange[0]}, ${monthsRange[monthsRange.length - 1]}`
            }

            httpHandlerServices.postMethod(getDataByCategory, dataProducto).then(
                async(res) => {
                    const data = await res;
                    setDataCategories({...dataCategories, ...dataCategories['product']= data});
                    
                }
            );

            httpHandlerServices.postMethod(getDataByCategory, dataServicio).then(

                async(res) => {
    
                    const data = await res;
    
                    setDataCategories({...dataCategories, ...dataCategories['service']= data})
    
                }
    
            );
    
            httpHandlerServices.postMethod(getDataByCategory, dataTiempo).then(
    
                async(res) => {
    
                    const data = await res;
    
                    setDataCategories({...dataCategories, ...dataCategories['time']= data})
    
                }
    
            );
    
            httpHandlerServices.postMethod(getDataByCategory, dataOtros).then(
    
                async(res) => {
    
                    const data = await res;
    
                    setDataCategories({...dataCategories, other: data})
    
                }
    
            );
                 
        }

        if(monthsRange) {
            requestGeneralData();
        }
    
    },[requestData, monthsRange])

    const [activeTab, setActiveTab] = useState('1');

    const [activeTab1, setActiveTab1] = useState(false);

    const [activeTab2, setActiveTab2] = useState(false);

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    const columns = [
        {field: "name", title: <div><i className="icomoon icon-shop mr-1" /> {texts.franchise}</div>},
        {field: "Cerradas", title: <div><i className="icomoon icon-conversando mr-1" /> {texts.answeredComplaints}</div>}, 
        {field: "Abiertas", title: <div><i className="icomoon icon-conversando mr-1" /> Open </div>}, 
        {field: "Progress", title: <div><i className="icomoon icon-sucursal mr-1" /> In Progress </div>}, 
        {field: "Total", title: <div><i className="icomoon icon-sucursal mr-1" /> Total Complaints</div>}, 
    ];

    const clearAll = (parent) => {
        
        if(parent === 'franchise') {
        
            setRequestData({
                ...requestData,
                franchise_id: "0",
                states: "0",
                cities: "0",
                stores: "0",
            })
            
        }

        if(parent === 'stores') {
            
            setRequestData({
                ...requestData,
                stores: "0",
                states: "0",
                cities: "0",
            })
            
        }

        if(parent === 'state') {
            
            setRequestData({
                ...requestData,
                states: "0",
                cities: "0",
            })
            
        }
    }

    return (
        <Layout>
            <div className="overview">
                <DatePicker updateRequestDate={updateRequestDate} monthRangeHandler={monthRangeHandler} requestData={requestData}/>
                <Filters updateRequestData={updateRequestData} clearAll={clearAll}/>
                <Container>
                    <div className='tabs'>
                        <Nav tabs>
                            <NavItem className={activeTab1 ? 'overviewTabs emigre-bold text-secondary activeTabs' : 'overviewTabs emigre-bold text-secondary'}>
                                <NavLink
                                    className={classnames({ active: activeTab === '1' } && 'emigre-bold text-secondary text-center' )}
                                    onClick={() => { 
                                        toggle('1'); 
                                        setActiveTab1(true)
                                        setActiveTab2(false)
                                    
                                    }}
                                >
                                    {texts?.generalViewTab}
                                </NavLink>
                            </NavItem>
                            <NavItem className={activeTab2 ? 'overviewTabs emigre-bold text-secondary activeTabs' : 'overviewTabs emigre-bold text-secondary'}>
                                <NavLink
                                    className={classnames({ active: activeTab === '2' }  && 'emigre-bold text-secondary text-center')}
                                    onClick={() => { 
                                        toggle('2'); 
                                        setActiveTab1(false)
                                        setActiveTab2(true)
                                    }}
                                >
                                    {texts?.franchiseView}
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Row>
                                    <Col sm="12">
                                        <Period dataGeneral={generalData}/>
                                        <HistoricalTotal dataGeneral={generalData} dataCategoriesResume={dataCategoriesResume} dataIncidences={dataIncidences} />
                                        <IncidencesByCategory dataIncidences={dataIncidences} dataCategoriesResume={dataCategoriesResume} dataCategories={dataCategories} />
                                        <PercentCategories dataCategoriesResume={dataCategoriesResume} dataCategories={dataCategories} />
                                        <ComplaintDetail dataCategoriesResume={dataCategoriesResume} dataIncidences={dataIncidences} />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                            <Row>
                                <Container>
                                    <Col sm="6">
                                        <h4 className='emigre-bold text-secondary'>{texts.complainsPerFranchise}</h4>
                                    </Col>
                                    
                                    <Col sm="12">
                                            <MaterialTable 
                                                title=""
                                                columns={columns} 
                                                data={generalData.franchise_list} 
                                                options={{filtering: true, headerStyle: {
                                                    backgroundColor: '#424242',
                                                    color: '#FFF',
                                                    textAlign: 'center',
                                                    fontWeight: 'Bold'
                                                }}}
                                                localization={{
                                                    body: {
                                                        emptyDataSourceMessage: texts.emptyData,
                                                        filterRow: {
                                                            filterTooltip: texts.filterMessage
                                                        }
                                                    },
                                                    toolbar: {
                                                    searchTooltip: texts.findMessage,
                                                    searchPlaceholder: texts.findMessage
                                                    },
                                                    pagination: {
                                                    labelRowsSelect: texts?.rowsMessage,
                                                    labelDisplayedRows: ' {from}-{to} of {count}',
                                                    firstTooltip: texts.toFirst,
                                                    previousTooltip: texts.moveBack,
                                                    nextTooltip: texts.moveNext,
                                                    lastTooltip: texts.toLast
                                                    }
                                                }}
                                            />
                                    </Col>
                                </Container>
                            </Row>
                            </TabPane>
                        </TabContent>
                    </div>    
                </Container>    

               
            </div>
        </Layout>
    )
}

export default Panel;