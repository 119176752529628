/* eslint-disable */
import React, {useEffect, useState, useContext} from 'react'
import { Input, Container, Row, Col } from 'reactstrap'
import moment from 'moment'
import './DatePicker.scss'
import { contextApp } from "../App/contextApp"
import HttpHandlerServices from '../../services/httpService';
import Loader from '../../components/loader/Loader';
import language from './language.json';

const DatePicker = (props) => { 

    const { updateRequestDate, monthRangeHandler, requestData } = props;
    const { currentLanguage, setSelectValue, selectValue } = useContext(contextApp);

    const [ startMonth, setStartMonth] = useState('');

    const [ endMonth, setEndMonth] = useState('');

    const [ loader, setLoader] = useState(false);

    const [texts, setTexts] = useState(language.english);

    useEffect(()=>{ if(currentLanguage === 'spanish') { setTexts(language.spanish)}},[currentLanguage]);

    useEffect(() => {

        let begining_date = moment().add(-3,'months').format('YYYY-MM');

        let ending_date = moment().format('YYYY-MM');

        setStartMonth(begining_date)

        setEndMonth(ending_date)

        monthRangeHandler(rangeMonthsSet(begining_date, ending_date))

    }, [])


    const selectedDate = (e, state) => {

        const value = moment(e.target.value).format('YYYY-MM');
        if(state === 'startMonth') {
            setStartMonth(value)
            updateRequestDate(`${value}, ${endMonth}`)
            monthRangeHandler(rangeMonthsSet(value, endMonth))
        } else {
            setEndMonth(value)
            updateRequestDate(`${startMonth}, ${value}`)
            monthRangeHandler(rangeMonthsSet(startMonth, value))
        }
        

    }

    const rangeMonthsSet = (start, end) =>{

        let f1 = moment(start);
        let f2 = moment(end);
        let m = f2.diff(f1,'months');
        let _month;
        let _months;
        let _monthsLabel=[];
        let _monthsData=[];
        let range = [];
        _monthsLabel[0] = moment(f1).format('MMM YY');
        _monthsData[0] = moment(f1).format('YYYY-MM');
        for(let i=1;i<=m;i++){
            _month = moment(f1.add(1,'months'));
            _monthsLabel[i] = _month.format('MMM YY');
            _monthsData[i] = _month.format('YYYY-MM');
        }
        _months = _monthsLabel;
        range[0] = _monthsData[0];
        range[1] = _monthsData[m];
        setSelectValue({...selectValue, monthsRange: _monthsData})
        setSelectValue({...selectValue, monthsLabel: _months})
        
        return _monthsData;

    }

    const getReport = (e) =>{
        e.preventDefault();
        setLoader(true)
        const httpHandlerServices = new HttpHandlerServices();
        const endpoint = `/export`;
        const monthsRange = rangeMonthsSet(startMonth, endMonth).join()
        const dataSend = {
            stores: undefined,
            months: monthsRange
        }

        httpHandlerServices.postMethodFile(endpoint, dataSend).then(
            async(res) => {
                const data = await res;
                setLoader(false)
                window.open(data, '_blank');
            }
        )
    }

    return (
        <Container className="date-picker">
            {loader ? <Loader /> : null}
            <Row>
                <Col xs={12} md={4} >
                    <a href="/" className="text_descarga" onClick={(e) => getReport(e)}>
                        <h6 className="emigre text-uppercase mt-2 text-right border-right border-secondary pr-3">
                            <b>{texts.downloadReport} <i className="icomoon icon-save ml-2"></i></b>
                        </h6>
                    </a>
                </Col>
                <Col xs={4} md={3} className="pr-0">
                    <Input
                        type="month"
                        name="startDate"
                        id="startDate"
                        value={startMonth}
                        onChange={(e) => selectedDate(e, 'startMonth') }
                        placeholder="datetime placeholder"
                        />
                </Col>
                <Col xs={1} md={1} className="">
                    <hr/>
                </Col>
                <Col xs={4} md={3} className="pl-0">
                    <Input
                        type="month"
                        name="endDate"
                        id="endDate"
                        value={endMonth}
                        onChange={(e) => selectedDate(e, 'endMonth')}
                        placeholder="datetime placeholder"
                        />
                </Col>     
            </Row>
        </Container>
    )
}

export default DatePicker;