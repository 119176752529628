import React, {useState, useEffect}  from 'react'
import moment from 'moment';
import 'moment-timezone';

const StopLight = ({value}) => {

    const [priority, setPriority] = useState(3)

    const MINUTE_MS = 60000 * 60;
    
    useEffect(()=>{
        
        const checkPriority = () => {
            var now = moment(new Date());
            var end = moment(value);
            const diff =  now.diff(end, 'hours');
            if(diff < 23) setPriority(0);
            if(diff > 23) setPriority(1);
            if(diff > 48) setPriority(2);
        }

        checkPriority()

        const interval = setInterval(() => {
            checkPriority()
        }, MINUTE_MS);
        
        return () => clearInterval(interval);
    },[MINUTE_MS, value])

    return(
        <span className='stoplight'>
            {priority === 2 && <span className='red-light'> </span>}
            {priority === 1 && <span className='yellow-light'> </span>}
            {priority === 0 && <span className='green-light'> </span>}
        </span>
    )
};


export default StopLight;